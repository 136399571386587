/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {getDashboard} from "../../../modules/services/services-list/core/_requests";
import {useApi} from "../../../components/ApiClient";
import {useAuth} from "../../../modules/auth";

type Props = {}

// eslint-disable-next-line no-empty-pattern
const CardsWidgetServices: FC<Props> = ({}) => {
    const api = useApi();
    const {auth} = useAuth();
    const [types, setTypes] = useState({withPay: 0, withoutPay: 0})

    useEffect(() => {
        getDashboard(api, auth.msisdn).then(
            el => {
                if (!el.data) {
                    return;
                }
                let withoutPay = 0;
                let withPay = 0;
                // eslint-disable-next-line
                const filteredData = el.data.filter(item => item.__typename !== "BillingNumberServiceAvailable");
                // eslint-disable-next-line
                filteredData.map(item => {item.fee?.amount !== 0 ? withPay += 1 : withoutPay += 1;});
                setTypes({withPay: withPay, withoutPay: withoutPay})
            }
        );
        // eslint-disable-next-line
    }, [auth.msisdn]);

    return (
        <div className='col-xl-4'>
            <div className='card card-xl-stretch my-3'>
                <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
                    <div className='d-flex flex-column content-justify-center flex-row-fluid h-100'>
                        <Link className={clsx('card-title fw-bold fs-5 mb-3 d-block', {active: false})}
                              to={'/services'}>Услуги</Link>
                        <div className='d-flex fw-semibold align-items-center my-5'>
                            <div className='text-gray-500 flex-grow-1 me-4'>Без абонентской платы</div>
                            <div className='fw-bolder text-gray-700 text-xxl-end'>{types.withoutPay}</div>
                        </div>
                        <div className='d-flex fw-semibold align-items-center my-5'>
                            <div className='text-gray-500 flex-grow-1 me-4'>С абонентской платой</div>
                            <div className='fw-bolder text-gray-700 text-xxl-end'>{types.withPay}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {CardsWidgetServices}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Order} from '../../core/_models'

type Props = {
    order: Order
}

const OrderInfoCell: FC<Props> = ({order}) => {
    return (
        <div className='d-flex align-items-center'>
            <div className='d-flex flex-column'>
                {order.type.name ? order.type.name : ""}
            </div>
        </div>
    )
}

export {OrderInfoCell}

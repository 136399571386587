import {FC} from 'react'

type Props = {
  callback: any
}

export const HelpModalButton: FC<Props> = ({callback}) => {
  return (
    <div className='text-gray-500 text-center fw-semibold fs-6'>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" onClick={() => {callback(true)}} className='link-primary'>
        Помощь
      </a>
    </div>
  )
}

import {FC} from 'react'
import {dateTimeToLocaleString} from "../../../../../utils/dateTimeToLocaleString";

type Props = {
  value: string
}

const OrderDateCell: FC<Props> = ({value}) => {

    return (
        <div className='d-flex align-items-center'>
            <div className='d-flex flex-column'>
                {dateTimeToLocaleString(value)}
            </div>
        </div>
    )
}

export {OrderDateCell}

import {KTCard} from "../../../../_metronic/helpers";
import {QueryResponseProvider, useQueryResponseData} from "./core/QueryResponseProvider";
import {useMemo} from "react";
import {OrdersTable} from "./table/OrdersTable";
import {QueryRequestProvider} from "./core/QueryRequestProvider";



const Content = () => {
    const orders = useQueryResponseData()
    const data = useMemo(() => orders, [orders])

    return (
        <div>
            <div className='card mb-5 mb-xl-10'>
                {/*<div*/}
                {/*    className='card-header border-0 cursor-pointer'*/}
                {/*    role='button'*/}
                {/*    data-bs-toggle='collapse'*/}
                {/*    data-bs-target='#kt_services_connected'*/}
                {/*    aria-expanded='true'*/}
                {/*    aria-controls='kt_services_connected'*/}
                {/*>*/}
                {/*    <div className='card-title m-0'>*/}
                {/*        <h3 className='fw-bolder m-0'>Подключенные</h3>*/}
                {/*    </div>*/}
                {/*</div>*/}
                    <KTCard>
                        <OrdersTable orders={data} />
                    </KTCard>
            </div>
            {/*<div className='card mb-5 mb-xl-10'>*/}
            {/*    <div*/}
            {/*        className='card-header border-0 cursor-pointer'*/}
            {/*        role='button'*/}
            {/*        data-bs-toggle='collapse'*/}
            {/*        data-bs-target='#kt_services_available'*/}
            {/*        aria-expanded='true'*/}
            {/*        aria-controls='kt_services_available'*/}
            {/*    >*/}
            {/*        <div className='card-title m-0'>*/}
            {/*            <h3 className='fw-bolder m-0'>Доступные</h3>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div id='kt_services_available' className='collapse show'>*/}
            {/*        <KTCard>*/}
            {/*            <ServicesTable services={available} isEnable={false}/>*/}
            {/*        </KTCard>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export const OrdersList = () => {
    return (

        <QueryRequestProvider>
            <QueryResponseProvider>
                <Content/>
            </QueryResponseProvider>
        </QueryRequestProvider>
    )
}
import clsx from 'clsx'
import {useAuth} from "../../../../app/modules/auth";
import {Link} from "react-router-dom";
import {GroupSelectWrapper} from './groups/GroupSelect';
import Media from "react-media";
import {AUTHORITIES, useAccess} from "../../../../app/components/client-configs/AccessProvider";

const itemClass = 'ms-1 ms-lg-3'

const Navbar = () => {
    const access = useAccess()
    const {logout} = useAuth();

    return <div className='app-navbar flex-shrink-0'>
        <div className={clsx('app-navbar-item', itemClass)}>
            <GroupSelectWrapper/>
        </div>

        <Media query="(min-width: 660px)">
            <div className={clsx('app-navbar-item', itemClass)}>
                {access[AUTHORITIES.PAYMENTS] && (
                    <div className='menu-item px-5 my-1'>
                        <Link to='/balance' className='menu-link px-5'>
                            Пополнить баланс
                        </Link>
                    </div>
                )}
                <div className='menu-item px-5 my-1'>
                    <Link to='/account' className='menu-link px-5'>
                        Профиль
                    </Link>
                </div>

                <div className='menu-item px-5'>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={logout} className='menu-link px-5'>
                        Выйти
                    </a>
                </div>
            </div>
        </Media>
    </div>
}

export {Navbar}

export function accessStatusParseCode(code: string) {
    switch (code) {
        case "REGISTERED": return "Номер зарегистрирован в ЛК.";
        case "NOT_REGISTERED": return "Ваш номер не зарегистрирован. Пожалуйста, пройдите процедуру регистрации.";
        case "FORBIDDEN": return "Доступ невозможен. Пожалуйста, обратитесь в службу поддержки.";
        case "NOT_FOUND": return "Номер не найден";
        case "DEACTIVATED": return "Доступ невозможен. Пожалуйста, обратитесь в службу поддержки.";
        case "UNKNOWN__": return "Неизвестная ошибка.";
        default: return "Неизвестная ошибка.";
    }
}
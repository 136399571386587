// @ts-nocheck
import {Column} from 'react-table'
import {OrderInfoCell} from './OrderInfoCell'
import {OrderCell} from './OrderCell'
import {OrderCustomHeader} from './OrderCustomHeader'
import {Service} from '../../core/_models'
import {OrderStatusCell} from "./OrderStatusCell";
import {OrderDateCell} from "./OrderDateCell";

const ordersColumns: ReadonlyArray<Column<Service>> = [
    {
        Header: (props) => (
            <OrderCustomHeader tableProps={props} title='Дата подачи' className='min-w-125px'/>
        ),
        id: 'date',
        Cell: ({...props}) => <OrderDateCell value={props.data[props.row.index].createdAt}/>,
    },
    {
        Header: (props) => <OrderCustomHeader tableProps={props} title='Название' className='min-w-125px'/>,
        id: 'name',
        Cell: ({...props}) => <OrderInfoCell order={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => (
            <OrderCustomHeader tableProps={props} title='Статус' className='min-w-125px'/>
        ),
        id: 'status',
        Cell: ({...props}) => <OrderStatusCell value={props.data[props.row.index].status}/>,
    },
    {
        Header: (props) => (
            <OrderCustomHeader tableProps={props} title='Описание' className='min-w-125px'/>
        ),
        id: 'description',
        Cell: ({...props}) => <OrderCell value={props.data[props.row.index].description}/>,
    },
    {
        Header: (props) => (
            <OrderCustomHeader tableProps={props} title='Результат' className='min-w-125px'/>
        ),
        id: 'result',
        Cell: ({...props}) => <OrderCell value={props.data[props.row.index].resultMessage}/>,
    },
    // {
    //   Header: (props) => (
    //     <OrderCustomHeader tableProps={props} title='Дата подключения' className='min-w-125px' />
    //   ),
    //   id: 'enabledAt',
    //   Cell: ({...props}) => <OrderEnabledAtCell value={props.data[props.row.index].enabledAt} />,
    // },
    // {
    //   Header: (props) => (
    //     <OrderCustomHeader tableProps={props} title='Стоимость' className='min-w-125px' />
    //   ),
    //   id: 'fee_amount',
    //   Cell: ({...props}) => <OrderRubCell value={props.data[props.row.index].fee.amount} />,
    // },
    // {
    //   Header: (props) => (
    //     <OrderCustomHeader tableProps={props} title='Тип стоимости' className='min-w-125px' />
    //   ),
    //   id: 'fee_type',
    //   Cell: ({...props}) => <ServiceFeeTypeCell value={props.data[props.row.index].fee.type} />,
    // },
    // {
    //   Header: (props) => (
    //     <OrderCustomHeader tableProps={props} title='Действия' className='text-end min-w-100px' />
    //   ),
    //   id: 'actions',
    //   Cell: ({...props}) => <OrderActionsCell service={props.data[props.row.index]} isEnable={true} />,
    // },
]

export {ordersColumns}

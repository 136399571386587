import {ApiClient} from "../../../../../app/components/ApiClient";
import {GET_GROUP} from "../../../../../app/components/_queries";
import {GroupsQueryResponse} from "./_models"

export const getGroup = async (client: ApiClient): Promise<GroupsQueryResponse> => {
    let result = await client.query({query: GET_GROUP});
    return {
        data: result.data.me.account.number.groups
    }
}

import {Link} from 'react-router-dom'
import {CSSProperties, useEffect, useState} from 'react'
import clsx from 'clsx'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {useConfig} from '../../../../app/components/client-configs/Configurator'

const SidebarLogo = () => {
    const {config} = useLayout();
    const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
    const [logoPath, setLogoPath] = useState<string>();
    const [logoStyle, setLogoStyle] = useState({} as CSSProperties);

    const [logoDivStyle, setLogoDivStyle] = useState({
        paddingTop: '4px',
        paddingBottom: '0',
        minHeight: '0',
    } as CSSProperties);

    const {logo: logoConfig, indexPage: indexPageConfig  } = useConfig()

    useEffect(() => {
        setLogoPath(logoConfig.url);
        setLogoStyle(logoConfig.style);

        const height = parseInt(logoConfig.style?.height?.toString() ?? "0");

        setLogoDivStyle({
            paddingTop: '4px',
            paddingBottom: '0',
            minHeight: (height + 41) + 'px',
        });
    }, [logoConfig])

    useEffect(() => {
        const bodyElement = document.body;

        const observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach((mutation) => {
                if (mutation.attributeName === 'data-kt-app-sidebar-minimize') {
                    const attributeValue = bodyElement.getAttribute('data-kt-app-sidebar-minimize');
                    setIsSidebarMinimized(attributeValue === 'on');
                }
            });
        });

        observer.observe(bodyElement, { attributes: true, attributeFilter: ['data-kt-app-sidebar-minimize'] });

        return () => {
            observer.disconnect();
        };
    }, []);

    const appSidebarDefaultMinimizeDesktopEnabled =
        config?.app?.sidebar?.default?.minimize?.desktop?.enabled
    const appSidebarDefaultCollapseDesktopEnabled =
        config?.app?.sidebar?.default?.collapse?.desktop?.enabled
    const toggleType = appSidebarDefaultCollapseDesktopEnabled
        ? 'collapse'
        : appSidebarDefaultMinimizeDesktopEnabled
            ? 'minimize'
            : ''
    const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
    const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default

    return (
        <div className='app-sidebar-logo px-6' id='kt_app_sidebar_logo' style={logoDivStyle}>
            <Link to='/dashboard'>
                {
                    isSidebarMinimized &&
                    <img
                        alt='Logo'
                        src={indexPageConfig.favicon}
                        className='w-30px'
                    />
                }
                {config.layoutType === 'dark-sidebar' ? (
                    <img
                        alt='Logo'
                        src={logoPath}
                        className='app-sidebar-logo-default'
                        style={logoStyle}
                    />
                ) : (
                    <>
                        <img
                            alt='Logo'
                            src={logoPath}
                            className='app-sidebar-logo-default theme-light-show'
                            style={logoStyle}
                        />
                        <img
                            alt='Logo'
                            src={logoPath}
                            className='app-sidebar-logo-default theme-dark-show'
                            style={logoStyle}
                        />
                    </>
                )}
            </Link>

            {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
                <div
                    id='kt_app_sidebar_toggle'
                    className={clsx(
                        'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
                        {active: appSidebarDefaultMinimizeDefault}
                    )}
                    data-kt-toggle='true'
                    data-kt-toggle-state={toggleState}
                    data-kt-toggle-target='body'
                    data-kt-toggle-name={`app-sidebar-${toggleType}`}
                >
                    <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-2 rotate-180'/>
                </div>
            )}
        </div>
    )
}

export {SidebarLogo}

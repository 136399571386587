import axios from 'axios'
import yaml from 'js-yaml'
import {createContext, CSSProperties, FC, useContext, useEffect, useState} from "react";
import {WithChildren} from "../../../_metronic/helpers";

export interface Config {
    api: ConfigApi
    indexPage: ConfigIndexPage
    logo: ConfigLogo
}

export interface ConfigApi {
    url: string
    authKey: string
}

export interface ConfigLogo {
    url: string
    style: CSSProperties
}

export interface ConfigIndexPage {
    favicon: string
    title: string
}

const domain = window.location.hostname
const ConfigContext = createContext<Config | undefined>(undefined)

export function useConfig(): Config {
    const ctx = useContext(ConfigContext)

    if (!ctx) {
        throw new Error('Illegal state: ConfigContext must be defined.')
    }

    return ctx
}

export const Configuration: FC<WithChildren> = ({children}) => {
    const [config, setConfig] = useState<Config>()

    useEffect(() => {
        loadDomainEnv(domain, 'config.yml')
            .then((it) => yaml.load(it) as Config)
            .then(setConfig)
    }, []);

    if (config === undefined) {
        return <></>;
    }

    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}

export function useHelp() {
    const [value, set] = useState<string>();

    useEffect(() => {
        if (value === undefined) {
            loadDomainEnv(domain, 'help.html').then(set)
        }
    }, [value])

    return value
}

export function useFooter() {
    const [value, set] = useState<string>();

    useEffect(() => {
        loadDomainEnv(domain, 'footer.html').then(set)
    }, [])

    return value
}

export function useRelease() {
    const [value, set] = useState({
        release: "",
        releasePopup: ""
    });

    useEffect(() => {
        loadDomainEnv(domain, 'release.html').then(release => {
            const releaseHasTitle = validateHtmlResponse(release);
            if (releaseHasTitle) throw Error;
            loadDomainEnv(domain, 'release-popup.html').then(releasePopup => {
                    const releasePopupHasTitle = validateHtmlResponse(releasePopup);
                    if (releasePopupHasTitle) throw Error;
                    set({release, releasePopup})
                }
            )
        }).catch(
            // @ts-ignore
            _ => set({release: false, releasePopup: false})
        )
    }, [])

    return value
}

async function loadDomainEnv(domain: string, file: string): Promise<string> {
    return (await axios.get('/configs/' + domain + '/' + file)).data
}


function validateHtmlResponse(html: string) {
    const parser = new DOMParser();
    const doc: Document = parser.parseFromString(html, 'text/html');
    return doc.title;
}
import {FC, useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseLoading} from '../core/QueryResponseProvider'
import {Order} from '../core/_models'
import {OrdersListLoading} from '../components/loading/OrdersListLoading'
import {OrdersListPagination} from '../components/pagination/OrdersListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {ordersColumns} from "./columns/_columns";

type Props = {
    orders: ReadonlyArray<Order>
}

const OrdersTable: FC<Props> = ({orders}) => {
    const data = useMemo(() => orders, [orders])
    const isLoading = useQueryResponseLoading()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns: ordersColumns,
        data,
    })

    return (
        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='kt_table_services'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              {...getTableProps()}
            >
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 va-middle'>
                  {headers.map((column: ColumnInstance<Order>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row: Row<Order>, i) => {
                    prepareRow(row)
                    return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        Не найдено информации
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <OrdersListPagination />
          {isLoading && <OrdersListLoading />}
        </KTCardBody>
    )
}

export {OrdersTable}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useAuth} from "../../../modules/auth";

const CardsWidgetUser: FC = () => {

  const { currentUser } = useAuth()

  return (
    <div className='col-xl-4'>
      <div className='card card-xl-stretch my-3'>
        <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
          <div className='d-flex flex-column content-justify-center flex-row-fluid h-100'>
            <Link className={clsx('card-title fw-bold fs-5 mb-3 d-block', {active: false})} to={'/account'} >Личные данные</Link>
            <div className='d-flex fw-semibold align-items-center my-2'>
              <div className='text-gray-500 flex-grow-1 me-4'>ФИО</div>
              <div className=' fw-bolder text-gray-700 text-xxl-end'>{currentUser?.contactName}</div>
            </div>
            <div className='d-flex fw-semibold align-items-center my-2'>
              <div className='text-gray-500 flex-grow-1 me-4'>Телефон</div>
              <div className='fw-bolder text-gray-700 text-xxl-end'>{currentUser?.contactPhone?.replace( /(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3-$4' )}</div>
            </div>
            <div className='d-flex fw-semibold align-items-center my-2'>
              <div className='text-gray-500 flex-grow-1 me-4'>Тариф</div>
              <div className='fw-bolder text-gray-700 text-xxl-end'>{currentUser?.billingNumber.pricePlan.name}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CardsWidgetUser}

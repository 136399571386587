import {useState} from 'react'
import { StepPhone } from './registration/StepPhone'
import { StepTypeEmail } from './registration/StepTypeEmail'
import { StepTypePhone } from './registration/StepTypePhone'
import { StepCode } from './registration/StepCode'

import { generateCorrelationId } from '../../../components/_helpers'
import { Verification, initialVerificationValues } from './forgotPassword/_models'
import { StepSuccess } from './registration/StepSuccess'

// 1 step - ввод номер телефона
// 2 step - ! RegistrationType.EMAIL
// 3 step - RegistrationType.EMAIL
// 4 step - ввод кода

export function Registration() {

  const [step, setStep] = useState(1)
  const [msisdn, setMsisdn] = useState('')
  const [correlationId] = useState(generateCorrelationId())
  const [registrationId] = useState(generateCorrelationId())
  const [verification, setVerification] = useState<Verification>(initialVerificationValues)

  return (
    <>
      {step === 1 && <StepPhone
        updateStep={setStep}
        setMsisdn={setMsisdn}
      />}
      {step === 2 && <StepTypePhone
        setMsisdn={setMsisdn}
        updateStep={setStep}
        correlationId={correlationId}
        setVerification={setVerification}
        registrationId={registrationId}
        msisdn={msisdn}
      />}
      {step === 3 && <StepTypeEmail
        setMsisdn={setMsisdn}
        updateStep={setStep}
        correlationId={correlationId}
        setVerification={setVerification}
        registrationId={registrationId}
        msisdn={msisdn}
      />}
      {step === 4 && <StepCode
        updateStep={setStep}
        correlationId={correlationId}
        verification={verification}
        registrationId={registrationId}
      />}
      {step === 5 && <StepSuccess/>}
    </>
  )
}

import {FC} from 'react'
import {OrderRequestStatus} from "../../core/_models";

type Props = {
  value: OrderRequestStatus
}

const statusCodeToText = (value: OrderRequestStatus): string => {
    switch (value) {
        case "ERROR": return "Ошибка";
        case "CANCELED": return "Отменено";
        case "PROCESSING": return "Обработка";
        case "OK": return "Выполнено";
        case "PENDING": return "Отправка";
        case "REJECTED": return "Отказано";
        case "UNKNOWN__": return "Неизвестно";
    }
}

const OrderStatusCell: FC<Props> = ({value}) => {
    return (
        <div className='d-flex align-items-center'>
            <div className='d-flex flex-column'>
                {statusCodeToText(value)}
            </div>
        </div>
    )
}

export {OrderStatusCell}

import {AuthModel} from './_models'

const initialAuth = {msisdn: ''}

const AUTH_LOCAL_STORAGE_KEY = 'ekomobile-auth'
const getAuth = (): AuthModel => {
  if (!localStorage) {
    return initialAuth
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return initialAuth
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      if (auth.access_token) {
        removeAuth()
        return initialAuth
      }
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }

  return initialAuth
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return 
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {ListViewProvider} from '../../../modules/operations/operations-list/core/ListViewProvider'
import {QueryRequestProvider} from '../../../modules/operations/operations-list/core/QueryRequestProvider'
import {QueryResponseProvider} from '../../../modules/operations/operations-list/core/QueryResponseProvider'
import {useQueryResponseData} from '../../../modules/operations/operations-list/core/QueryResponseProvider'
import {OperationsPieChart} from "../../../modules/operations/operations-list/components/pie-chart/OperationsPieChart";
import {SelectMonthProvider} from "../../../modules/operations/operations-list/core/SelectMonthProvider";

type Props = {}

// eslint-disable-next-line no-empty-pattern
const CardsWidgetOperations: FC<Props> = ({}) => { // FIXME: Unexpected empty object pattern    no-empty-pattern

    const operations = useQueryResponseData()
    return (
        <div className='col-xl-4'>
            <div className='card card-xl-stretch my-3'>
                <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
                    <div className='d-flex flex-column content-justify-center flex-row-fluid h-100'>
                        <Link className={clsx('card-title fw-bold fs-5 mb-3 d-block', {active: false})}
                              to={'/operations'}>Расходы за последний месяц</Link>

                        <div className="d-flex justify-content-center">
                            <OperationsPieChart operations={operations}/>
                        </div>
                        {/*{data.length > 0 ? (*/}
                        {/*    data.splice(0, 3).map((operation: Operation, i) => {*/}
                        {/*        return (*/}
                        {/*            <div key={i} className='d-flex fw-semibold align-items-center my-2'>*/}
                        {/*                <div className='text-gray-500 flex-grow-1 me-4'>{operation.name}</div>*/}
                        {/*                <div*/}
                        {/*                    className=' fw-bolder text-gray-700 text-xxl-end'>{priceWithSpaces(operation.amount)}</div>*/}
                        {/*            </div>*/}
                        {/*        )*/}
                        {/*    })*/}
                        {/*) : (*/}
                        {/*    <>*/}
                        {/*        <div className='d-flex fw-semibold align-items-center my-2'>*/}
                        {/*            <div className='text-gray-500 flex-grow-1 me-4'>&nbsp;</div>*/}
                        {/*            <div className=' fw-bolder text-gray-700 text-xxl-end'>&nbsp;</div>*/}
                        {/*        </div>*/}
                        {/*        <div className='d-flex fw-semibold align-items-center my-2'>*/}
                        {/*            <div className='text-gray-500 flex-grow-1 me-4'>&nbsp;</div>*/}
                        {/*            <div className=' fw-bolder text-gray-700 text-xxl-end'>&nbsp;</div>*/}
                        {/*        </div>*/}
                        {/*        <div className='d-flex fw-semibold align-items-center my-2'>*/}
                        {/*            <div className='text-gray-500 flex-grow-1 me-4'>&nbsp;</div>*/}
                        {/*            <div className=' fw-bolder text-gray-700 text-xxl-end'>&nbsp;</div>*/}
                        {/*        </div>*/}
                        {/*    </>*/}
                        {/*)}*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

const CardsWidgetOperationsWrapper = () => (
    <SelectMonthProvider>
        <QueryRequestProvider>
            <QueryResponseProvider>
                <ListViewProvider>
                    <CardsWidgetOperations/>
                </ListViewProvider>
            </QueryResponseProvider>
        </QueryRequestProvider>
    </SelectMonthProvider>
)

export {CardsWidgetOperationsWrapper}

import "yup-phone";
import {Link} from 'react-router-dom'

export const StepSuccess = () => {

  return (
    <div className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Регистрация</h1>
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Регистрация прошла успешно</div>
        </div>
      </div>
      <div className='text-gray-500 text-center fw-semibold fs-6'>
        <Link className='link-primary' to='/auth/login'>Авторизация</Link>
      </div>
    </div>
  )
}

import {createContext, Dispatch, FC, SetStateAction, useContext, useState} from "react";
import {WithChildren} from "../../../../../_metronic/helpers";

interface SelectMonthProvider {
    selectedMonth: number,
    setSelectedMonth: Dispatch<SetStateAction<number>>
}
const selectMonthInitialState = {} as SelectMonthProvider;

const Context = createContext<SelectMonthProvider>(selectMonthInitialState);
export const useSelectMonth = () => useContext<SelectMonthProvider>(Context);

// eslint-disable-next-line
export const SelectMonthProvider: FC<WithChildren> = ({children}) => { // @ts-ignore
    const [selectedMonth, setSelectedMonth] = useState((new Date()).getMonth());
    return <Context.Provider value={{selectedMonth, setSelectedMonth}}>{children}</Context.Provider>
}

import {ApiClient} from "../../../../components/ApiClient";

import {ACCESS_STATUS, REGISTRATION_TYPE} from '../../../../components/_queries'

export async function accessStatus(client: ApiClient, msisdn: string) {
    return await client.query({query: ACCESS_STATUS, variables: {msisdn: msisdn}}).then((result) => {
        return {data: result.data}
    });
}

export async function registrationType(client: ApiClient, msisdn: string) {
    return client.query({query: REGISTRATION_TYPE, variables: {msisdn: msisdn}}).then((result) => {
        return {data: result.data}
    });
}

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, /*useRef,*/ FC} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {registrationCreate, registrationVerify} from '../../../../components/_requests'
import {generateCorrelationId} from '../../../../components/_helpers'
import InputMask from 'react-input-mask'
import Inputmask from 'inputmask';
import {useApi} from "../../../../components/ApiClient";
import Popup from "reactjs-popup";

const phoneRegExp = /\+7\s[(][0-9]{3}[)]\s[0-9]{3}[-][0-9]{2}[-][0-9]{2}$/

const simRegExp = /^[0-9]+$/

//const emailRegExp = /^\S*$/

type Props = {
    updateStep: any
    correlationId: string
    setVerification: any
    registrationId: string
    msisdn: string
    setMsisdn: any
}

const initialValues = {
    sim: '',
    email: '',
    contactName: '',
    contactPhone: '',
}

const stepSchema = Yup.object().shape({
    sim: Yup.string()
        .matches(simRegExp, 'Поле не может содержать буквы и иные символы, кроме цифр')
        .min(17, 'Минимальная длина 17 символов')
        .max(20, 'Минимальная длина 20 символов')
        .required('Серийный номер Sim-карты обязателен'),
    email: Yup.string()
        //.matches(emailRegExp, 'Укажите корректный email')
        .email('Укажите корректный email')
        .required('Email обязателен'),
    contactName: Yup.string()
        .required('Контактное имя обязательно'),
    contactPhone: Yup.string()
        .matches(phoneRegExp, 'Контактный номер телефона указан не верно')
        .required('Контактный номер телефона обязателен'),
})

export const StepTypeEmail: FC<Props> = ({
                                             updateStep,
                                             correlationId,
                                             setVerification,
                                             registrationId,
                                             msisdn,
                                             setMsisdn
                                         }) => {
    const [loading, setLoading] = useState(false)
    const [/*hasErrors*/, setHasErrors] = useState(false)
    const apiClient = useApi()
    const formik = useFormik({
        initialValues,
        validationSchema: stepSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            // updateStep(4)
            try {
                //setMsisdn(values.contactPhone.replace(/[^0-9]/g, '').substring(1))
                registrationCreate(
                    apiClient,
                    msisdn,
                    correlationId,
                    generateCorrelationId(),
                    registrationId,
                    values.sim,
                    values.email,
                    values.contactName,
                    values.contactPhone.replace(/[^0-9]/g, '').substring(1)
                )
                    .then(({data: {registrationCreate}}) => {
                        if (registrationCreate.errors && registrationCreate.errors[0] && registrationCreate.errors[0].error.name === "REGISTRATION_INVALID_SIM") {
                            setStatus("Неверный серийный номер сим-карты");
                            setLoading(false);
                            setHasErrors(true);
                            setSubmitting(false);
                            return;
                        }
                        registrationVerify(apiClient, registrationId, correlationId, generateCorrelationId())
                            .then(({data: {registrationVerify}}) => {
                                setVerification(registrationVerify.verification)
                                updateStep(4)
                                setHasErrors(false)
                                setLoading(false)
                            })
                        setHasErrors(false)
                        setLoading(false)
                    })
                    .catch(({message}) => {
                        setHasErrors(true)
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('Возникла непредвиденная ошибка. Обратитесь, пожалуйста, к оператору службы поддержки.')
                    })
            } catch (error) {
                console.error(error)
                setStatus('Возникла непредвиденная ошибка. Обратитесь, пожалуйста, к оператору службы поддержки.')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    //const emailRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const emailInput = document.getElementById('email') as HTMLInputElement;
        const emailMask = new Inputmask('email', {
            //removeMaskOnSubmit: false,
            //clearMaskOnLostFocus: false,
            //insertMode: true,
            tabThrough: true,
            //positionCaretOnClick: 'radixFocus',
            //shiftPositions: false,
        });

        emailMask.mask(emailInput);

        /*emailInput.addEventListener('keydown', function (event) {
          const keyCode = event.keyCode || event.which;
          console.log('QQ');
          if (keyCode === 32) {
            console.log('!!');
            const rightArrowEvent = new KeyboardEvent('input', {
              which: 37,
            });
            emailInput.dispatchEvent(rightArrowEvent);
          }
        })*/
    }, [])

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_registration_first_form'
            onSubmit={formik.handleSubmit}
        >
            {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            )}

            <div className='fv-row mb-8'>
                <Popup
                    trigger={
                        <label className='form-label fw-bolder text-dark fs-6'>
                            Серийный номер SIM-карты (ICC) <img width="16" height="16" src="https://img.icons8.com/small/16/help.png" alt="help"/>
                        </label>
                    }
                    position={'right bottom'}
                    on="hover"
                    arrow={false}
                    keepTooltipInside={true}
                >
                    <div className="tooltip-content m-lg-5 bg-white shadow p-5 rounded">
                        Как найти серийный номер SIM-карты и заполнить данные:
                        <ol>
                            <li>возьмите пластиковую основу SIM-карты в руки;</li>
                            <li>поверните её так, чтобы видеть оборотную сторону;</li>
                            <li>рядом со штрих-кодом Вы увидите серийный номер;</li>
                            <li>введите только цифры этого номера в соответствующее поле.</li>
                        </ol>
                    </div>
                </Popup>

                <input
                    placeholder='Серийный номер Sim-карты (ICC)'
                    type='text'
                    autoComplete='off'
                    onKeyDown={(ev) => {
                        if (ev.currentTarget.value.length >= 20 && ev.key !== 'Backspace' && ev.key !== 'Delete') {
                            ev.preventDefault();
                        }
                    }}
                    {...formik.getFieldProps('sim')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.sim && formik.errors.sim,
                        },
                        {
                            'is-valid': formik.touched.sim && !formik.errors.sim,
                        }
                    )}
                />
                {formik.touched.sim && formik.errors.sim && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.sim}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Email</label>
                <input
                    placeholder='Email'
                    type='text'
                    id='email'
                    //		  ref={emailRef}
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.email && formik.errors.email,
                        },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Контактное имя</label>
                <input
                    placeholder='Контактное имя'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('contactName')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.contactName && formik.errors.contactName,
                        },
                        {
                            'is-valid': formik.touched.contactName && !formik.errors.contactName,
                        }
                    )}
                />
                {formik.touched.contactName && formik.errors.contactName && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.contactName}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Контактный номер телефона</label>
                <InputMask mask={'+7 (999) 999-99-99'}
                           placeholder='Контактный номер телефона'
                           type='text'
                           autoComplete='off'
                           {...formik.getFieldProps('contactPhone')}
                           className={clsx(
                               'form-control bg-transparent',
                               {
                                   'is-invalid': formik.touched.contactPhone && formik.errors.contactPhone,
                               },
                               {
                                   'is-valid': formik.touched.contactPhone && !formik.errors.contactPhone,
                               }
                           )}
                />
                {formik.touched.contactPhone && formik.errors.contactPhone && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.contactPhone}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Отправить</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
              Пожалуйста подождите...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                    )}
                </button>

                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-lg btn-light-primary w-100 mb-5'
                    >
                        Авторизация
                    </button>
                </Link>
            </div>
        </form>
    )
}

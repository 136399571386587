import {FC, useEffect, useState} from "react";
import {Operation} from "../../core/_models";
import {VictoryPie} from "victory-pie";
import {VictoryLegend} from "victory-legend";
import {typesCell as searchType} from "../../table/columns/CustomRow";
import {months} from "../header/OperationsListFilter";
import c from "./style.module.scss";
import {LineSegment} from "victory";


const types = [
    "VOICE",
    "DATA",
    "SERVICE",
    "PRICE_PLAN",
    "PAYMENT",
    "OTHER",
    "UNKNOWN",
    "SMS",
    "MINUTES",
    "INTERNET",
    "DAY",
    "MONTH"
]

interface IProps {
    month?: number;
    operations: Operation[];
}

const colors = ["#323248", "#009ef7", "#b93993"];

export const OperationsPieChart: FC<IProps> = ({operations, month}) => {
    // const [series, set] = useState<number[]>([]);
    const [data, set] = useState<{ x: string, y: number }[]>([]);

    useEffect(() => {
        set([])
        const series = types.map(el => {
            let amount = 0;
            const filtered = operations.filter(op => op.type === el);
            filtered.map(el => {
                // @ts-ignore
                return amount += el.amount;
            })
            return {
                x: searchType[el],
                y: filtered.length,
                label: searchType[el] + ": " + Math.abs(amount / 100) + "₽"
            };
        }).filter(item => item.y > 0);
        set(series)
    }, [operations]);

    return (
        <div className={c.container} style={{height: "fit-content"}}>
            {/* @ts-ignore */}
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div className={c["pie-container"]}>
                    <VictoryPie
                        width={200}
                        height={200}
                        data={data}
                        colorScale={colors}
                        labelIndicator={<LineSegment style={{stroke:"black", strokeDasharray:1,fill: "none",}}/>}

                        /* @ts-ignore */
                        // Вызывает ошибку
                        // <VictoryTooltip
                        //     cornerRadius={10}
                        //     pointerWidth={10}
                        //     pointerLength={10}
                        //     style={{ fontSize: '16px', fill: '#FFF' }}
                        //     flyoutStyle={{ fill: '#414B5F' }}
                        // />
                    />
                </div>
            </div>
            <div className={c["legend-container"]}>
                <p className="bold" style={{fontSize: "16px"}}>
                    {month !== undefined ? <>Расходы за {months[month]}: </> : ""}
                    {/* @ts-ignore */}
                    {Math.abs(operations.reduce((acc, current) => acc + current.amount, 0)) / 100} ₽
                </p>
                <VictoryLegend
                    centerTitle
                    gutter={10}
                    width={185}
                    height={100}
                    orientation="vertical"
                    data={data.map((el, ind) => {
                        return {name: el.x, symbol: {fill: colors[ind]}}
                    })}
                />
            </div>
        </div>
    )
}
import clsx from 'clsx'
import {useLayout} from '../../core'
import {useFooter} from "../../../../app/components/client-configs/Configurator";

const FooterWrapper = () => {
  const footerContent = useFooter()
  const {config} = useLayout()

  return (
    <div className='app-footer' id='kt_app_footer'>
      <div className={clsx('app-container', config?.app?.footer?.containerClass)}>
        {footerContent && <div dangerouslySetInnerHTML={{__html: footerContent}}/>}
      </div>
    </div>
  )
}

export {FooterWrapper}

import {SidebarMenuItem} from './SidebarMenuItem'
import Media from "react-media";
import {useAuth} from "../../../../../app/modules/auth";
import {AUTHORITIES, useAccess} from "../../../../../app/components/client-configs/AccessProvider";

const SidebarMenuMain = () => {
    const access = useAccess()
    const {logout} = useAuth();

    return (
        <>
            <SidebarMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/art/art002.svg'
                title='Главная'
                fontIcon='bi-app-indicator'
            />
            {access[AUTHORITIES.MANAGE_GROUPS] && (
                <SidebarMenuItem
                    to='/group'
                    title='Дочерние номера'
                    hasBullet={false}
                    icon='/media/icons/duotune/art/numbers.svg'
                    fontIcon="bi-app-indicator"
                />
            )}
            {/* <SidebarMenuItem to='/tasks' title='Заявки' hasBullet={false} /> */}
            <SidebarMenuItem
                to='/services'
                title='Услуги'
                hasBullet={false}
                icon='/media/icons/duotune/art/services.svg'
                fontIcon="bi-app-indicator"
            />
            {/* <SidebarMenuItem to='/tariff' title='Тариф' hasBullet={false} /> */}
            <SidebarMenuItem
                to='/operations'
                title='Расходы'
                hasBullet={false}
                icon='/media/icons/duotune/art/month_expenses.svg'
                fontIcon="bi-app-indicator"
            />
            <SidebarMenuItem
                to='/details'
                title='Детализация'
                hasBullet={false}
                icon='/media/icons/duotune/art/detail.svg'
                fontIcon="bi-app-indicator"
            />
            <SidebarMenuItem
                to='/remains'
                title='Остатки пакетов'
                hasBullet={false}
                icon='/media/icons/duotune/art/package_balance.svg'
                fontIcon="bi-app-indicator"
            />
            {access[AUTHORITIES.PAYMENTS] && (
                <SidebarMenuItem
                    to='/balance'
                    title='Пополнение'
                    hasBullet={false}
                    icon='/media/icons/duotune/art/balance.svg'
                    fontIcon="bi-app-indicator"
                />
            )}
            <SidebarMenuItem
                to='/request'
                title='Заявки'
                hasBullet={false}
                icon='/media/icons/duotune/art/orders.svg'
                fontIcon="bi-app-indicator"
            />
            <Media query="(max-width: 559px)">
                <>
                    <SidebarMenuItem
                        to='/account'
                        title='Профиль'
                        hasBullet={false}
                        icon=''
                        fontIcon="bi-app-indicator"
                    />

                    <div className='menu-item'>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={logout} className='menu-link'>
                            Выйти
                        </a>
                    </div>
                </>
            </Media>
        </>
    )
}

export {SidebarMenuMain}

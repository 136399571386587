import {RemainsQueryResponse} from './_models'
import {ApiClient} from "../../../../components/ApiClient";
import {GET_REMAINS} from "../../../../components/_queries";

const getRemains = async (client: ApiClient, msisdn: string): Promise<RemainsQueryResponse> => {
    let result = await client.query({query: GET_REMAINS, variables: {msisdn: msisdn}});
    return {data: result.data.me.account.billingNumber.remains.simple}
}

export {getRemains}

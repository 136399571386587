// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {Operation} from '../../core/_models'

type Props = {
  row: Row<Operation>
}
export const typesCell: {[index: string]:any} = {
  VOICE: "SMS",
  DATA: "Услуги",
  SERVICE: "Услуга",
  PRICE_PLAN: "Тарифный план",
  PAYMENT: "Прочие списания",
  OTHER: "Другое",
  UNKNOWN: "Неизвестно",
  SMS: "SMS",
  MINUTES: "Минуты",
  INTERNET: "Интернет",
  DAY: "В день",
  MONTH: "В месяц"
}

const CustomRow: FC<Props> = ({row}) => {
  return (
      <tr {...row.getRowProps()}>
        {row.cells.map((cell) => {
          if (cell.column.id === "type") {
            return <td {...cell.getCellProps()}>{typesCell[cell.row.original.type] ?? ''}</td>
          }
          return (
              <td
                  {...cell.getCellProps()}
                  className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
              >
                {cell.render('Cell')}
              </td>
          )
        })}
      </tr>
  )
}

export {CustomRow}

import {useState, FC} from 'react'
import * as Yup from 'yup'
import "yup-phone";
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'

import { generateCorrelationId } from '../../../../components/_helpers';
import { passwordChangeCreate} from '../../../../components/user/password/_requests'

import InputMask from 'react-input-mask'
import {useApi} from "../../../../components/ApiClient";

const phoneRegExp = /\+7\s[(][0-9]{3}[)]\s[0-9]{3}[-][0-9]{2}[-][0-9]{2}$/

type Props = {
    updateStep: any
    correlationId: any
    passwordChangeId: any
    setVerification: any
}

const initialValues = {
  phone: '',
}

const phoneSchema = Yup.object().shape({
  phone: Yup.string()
      .matches(phoneRegExp, 'Номер телефона указан не верно')
      .required('Номер телефона обязателен'),
})

export const StepPhone: FC<Props> = ({updateStep, correlationId, passwordChangeId, setVerification}) => {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [error, setError] = useState('')
  const apiClient = useApi()
  const formik = useFormik({
    initialValues,
    validationSchema: phoneSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      passwordChangeCreate(apiClient, values.phone.replace(/[^0-9]/g, '').substring(1), passwordChangeId, correlationId, generateCorrelationId())
        .then(({data: {passwordChangeCreate}}) => {
          setVerification(passwordChangeCreate.verification)
          setHasErrors(false)
          setLoading(false)
          updateStep(2)
        })
        .catch(({graphQLErrors, message}) => {
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          if (graphQLErrors[0].extensions.error.name === "ACCOUNT_NOT_FOUND") {
              setError("Номер не найден")
              setStatus("Номер не найден")
          } else {
              setError(message)
              setStatus(message)
          }
        })
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Забыли пароль ?</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
        Введите ваш номер телефона для восстановления пароля.
        </div>
      </div>

      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {error}
          </div>
        </div>
      )}

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Телефон</label>
        <InputMask mask={'+7 (999) 999-99-99'}
            {...formik.getFieldProps('phone')}
            type='text'
            placeholder='Телефон'
            autoComplete='off'
            className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.phone && formik.errors.phone},
                {
                  'is-valid': formik.touched.phone && !formik.errors.phone,
                }
            )}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Отправить</span>
          {loading && (
            <span className='indicator-progress'>
              Пожалуйста подождите...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Отмена
          </button>
        </Link>{' '}
      </div>
    </form>
  )
}

import {useRelease} from "../../../../app/components/client-configs/Configurator";
import Popup from "reactjs-popup";

export const SibebarRelease = () => {
    const {release, releasePopup} = useRelease();
    return release && releasePopup ? (
        <div className="menu menu-column menu-rounded menu-sub-indention px-3 mt-auto release">
            <div className="menu-item">
                <Popup trigger={
                    <div
                        className="menu-link without-sub text-center flex-column"
                        style={{
                            color: "rgb(157, 157, 166)"
                        }}
                        dangerouslySetInnerHTML={{__html: release}}
                    ></div>
                }
                       position="right bottom"
                       on="hover"
                >
                    <div
                        className="m-lg-5 bg-white shadow p-5 rounded"
                        dangerouslySetInnerHTML={{__html: releasePopup}}
                    ></div>
                </Popup>
            </div>
        </div>
    ) : <></>;
}
/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
    createResponseContext,
    initialQueryResponse,
    initialQueryState,
    PaginationState,
    QUERIES,
    stringifyRequestQuery,
    WithChildren,
} from '../../../../../_metronic/helpers'
import {useAuth} from '../../../auth'
import {getExpenses} from './_requests'
import {Operation} from './_models'
import {useQueryRequest} from './QueryRequestProvider'
import {useApi} from "../../../../components/ApiClient";
import {useSelectMonth} from "./SelectMonthProvider";

const QueryResponseContext = createResponseContext<Operation>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
    const {selectedMonth} = useSelectMonth();
    const {state} = useQueryRequest()
    const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
    const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
    const apiClient = useApi()

    useEffect(() => {
        if (query !== updatedQuery) {
            setQuery(updatedQuery)
        }
    }, [updatedQuery])

    const {auth} = useAuth()
    const {
        isFetching,
        refetch,
        data: response,
    } = useQuery(
        `${QUERIES.EXPENSES_LIST}-${query}`,
        () => {
            const date = new Date()
            return getExpenses(apiClient, date.getFullYear(), selectedMonth + 1, auth.msisdn)
        },
        {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
    )

    return (
        <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
            {children}
        </QueryResponseContext.Provider>
    )
}


const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
    const {response} = useQueryResponse()
    if (!response) {
        return []
    }

    return response?.data || []
}

const useQueryResponsePagination = () => {
    const defaultPaginationState: PaginationState = {
        links: [],
        ...initialQueryState,
    }

    const {response} = useQueryResponse()
    if (!response || !response.payload || !response.payload.pagination) {
        return defaultPaginationState
    }

    return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
    const {isLoading} = useQueryResponse()
    return isLoading
}

export {
    QueryResponseProvider,
    useQueryResponse,
    useQueryResponseData,
    useQueryResponsePagination,
    useQueryResponseLoading,
}

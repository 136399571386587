/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, FC} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import { accessStatus, registrationType } from './_requests'
import InputMask from 'react-input-mask'
import {useApi} from "../../../../components/ApiClient";
import {accessStatusParseCode} from "../../../../utils/accessStatusParseCode";

const phoneRegExp = /\+7\s[(][0-9]{3}[)]\s[0-9]{3}[-][0-9]{2}[-][0-9]{2}$/

type Props = {
  updateStep: any
  setMsisdn: any
}

const initialValues = {
  phone: '',
}

const stepSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneRegExp, 'Номер телефона указан не верно')
    .required('Телефон обязателен'),
})

export const StepPhone: FC<Props> = ({updateStep, setMsisdn}) => {
  const [loading, setLoading] = useState(false)
  const apiClient = useApi()
  const formik = useFormik({
    initialValues,
    validationSchema: stepSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      let hasErrors = false;
      setLoading(true)
      try {
        setMsisdn(values.phone.replace(/[^0-9]/g, '').substring(1))
        const {data: status} = await accessStatus(apiClient, values.phone.replace(/[^0-9]/g, '').substring(1));
        if (status.accessStatus.signUpDenyReason) {
            setSubmitting(false);
            setLoading(false);
          return setStatus(accessStatusParseCode(status.accessStatus.signUpDenyReason));
        }
        const registrationTypeResult = await registrationType(apiClient, values.phone.replace(/[^0-9]/g, '').substring(1))
        if (registrationTypeResult.data.registrationType === 'PHONE') {
          updateStep(2)
        } else if (registrationTypeResult.data.registrationType === 'EMAIL') {
          updateStep(3)
        } else {
          // FIXME: no-throw-literal
          // eslint-disable-next-line no-throw-literal
          throw 'Возникла непредвиденная ошибка. Обратитесь, пожалуйста, к оператору службы поддержки.'
        }
      } catch (error) {
        setStatus('Возникла непредвиденная ошибка. Обратитесь, пожалуйста, к оператору службы поддержки.')
        hasErrors = true;
      }

      if (hasErrors) {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_registration_first_form'
      onSubmit={formik.handleSubmit}
    >
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Телефон</label>
        <InputMask mask={'+7 (999) 999-99-99'}
          placeholder='Телефон'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('phone')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.phone && formik.errors.phone,
            },
            {
              'is-valid': formik.touched.phone && !formik.errors.phone,
            }
          )}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Продолжить</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Пожалуйста подождите...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Авторизация
          </button>
        </Link>
      </div>
    </form>
  )
}

import {OrdersQueryResponse} from './_models'
import {ApiClient} from "../../../../components/ApiClient";
import {GET_REQUESTS} from "../../../../components/_queries";

const getOrders = async (client: ApiClient): Promise<OrdersQueryResponse> => {
  let result = await client.query({query: GET_REQUESTS});
  return {
    data: result.data.me.account.requestList.nodes
  }
}

export {getOrders}

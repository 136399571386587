import {useEffect, useMemo, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useSelectMonth} from "../../core/SelectMonthProvider";


export const months = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
];
function getMonth() {
    const currentMonth = new Date().getMonth();
    let startIndex = currentMonth - 4;
    if (startIndex < 0) {
        startIndex += 12; // Если индекс отрицательный, добавляем 12 для получения корректного индекса
    }

    const options = [];
    for (let i = 0; i < 5; i++) {
        const index = (startIndex + i) % 12; // Используем остаток от деления на 12 для получения корректного индекса
        options.push(months[index]);
    }
    return options;
}


const OperationsListFilter = () => {
    const {setSelectedMonth} = useSelectMonth();
    const {updateState} = useQueryRequest();
    const [active, setActive] = useState<number>((new Date()).getMonth());
    const {isLoading} = useQueryResponse();
    const options = useMemo(() => getMonth(), []);


    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const resetData = () => {
        updateState({filter: undefined, ...initialQueryState})
    }

    const filterData = () => {
        setSelectedMonth(active as number);
        updateState({
            filter: {month: active},
            ...initialQueryState,
        })
    }

    return (
        <>
            {/* begin::Filter Button */}
            <button
                disabled={isLoading}
                type='button'
                className='btn btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2'/>
                Фильтр
            </button>
            {/* end::Filter Button */}
            {/* begin::SubMenu */}
            <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                {/* begin::Header */}
                <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>Фильтр</div>
                </div>
                {/* end::Header */}

                {/* begin::Separator */}
                <div className='separator border-gray-200'></div>
                {/* end::Separator */}

                {/* begin::Content */}
                <div className='px-7 py-5' data-kt-operation-table-filter='form'>
                    {/* begin::Input group */}
                    <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Месяц:</label>
                        <select
                            className='form-select form-select-solid fw-bolder'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            data-kt-operation-table-filter='role'
                            data-hide-search='true'
                            onChange={(e) => setActive(months.indexOf(e.target.value as string))}
                            value={options[active]}
                        >
                            {options.map((el, ind) => <option key={ind} value={el}>{el}</option>)}
                        </select>
                    </div>
                    {/* end::Input group */}

                    {/* begin::Actions */}
                    <div className='d-flex justify-content-end'>
                        <button
                            type='button'
                            disabled={isLoading}
                            onClick={resetData}
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-operation-table-filter='reset'
                        >
                            Сбросить
                        </button>
                        <button
                            disabled={isLoading}
                            type='button'
                            onClick={filterData}
                            className='btn btn-primary fw-bold px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-operation-table-filter='filter'
                        >
                            Применить
                        </button>
                    </div>
                    {/* end::Actions */}
                </div>
                {/* end::Content */}
            </div>
            {/* end::SubMenu */}
        </>
    )
}

export {OperationsListFilter}

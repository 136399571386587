import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {Configuration} from "./app/components/client-configs/Configurator";
import {Api} from "./app/components/ApiClient";
import {AccessProvider} from "./app/components/client-configs/AccessProvider";

import moment from "moment";
import 'moment/locale/ru'; // https://stackoverflow.com/a/56181603/519917

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)
moment.locale('ru')

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
    createRoot(container).render(
        <Configuration>
            <Api>
                <QueryClientProvider client={queryClient}>
                    <MetronicI18nProvider>
                        <AuthProvider>
                            <AccessProvider>
                                <AppRoutes/>
                            </AccessProvider>
                        </AuthProvider>
                    </MetronicI18nProvider>
                    <ReactQueryDevtools initialIsOpen={false}/>
                </QueryClientProvider>
            </Api>
        </Configuration>
    )
}

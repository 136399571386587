import {useEffect} from 'react'
import {useConfig} from './app/components/client-configs/Configurator'

const PageVariables = () => {
    const config = useConfig().indexPage

    useEffect(() => {
        let shortIconSelector = document.head.querySelector('[rel="shortcut icon"]')
        if (config.favicon && shortIconSelector) {
            shortIconSelector.setAttribute('href', config.favicon)
        }
        let title = document.head.querySelector('title')
        if (config.title && title) {
            title.text = config.title
        }
    }, [config])

    return <></>
}

export {PageVariables}

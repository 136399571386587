import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import {ApiClient} from "../../../components/ApiClient";
import {GET_USER_QUERY} from "../../../components/_queries";
import {ConfigApi} from '../../../components/client-configs/Configurator';

export async function login(cfg: ConfigApi, phone: string, password: string) {
    const loginUrl = `${cfg.url}/auth/v1/login/password`
    let data = `username=${phone}&password=${password}`

    return await axios.post<AuthModel>(loginUrl, data, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'X-Auth-Client-Key': cfg.authKey,
        }
    })
}

async function queryUser(client: ApiClient, msisdn: string) {
    return client.query({query: GET_USER_QUERY, variables: {msisdn: msisdn}}).then((result) => {
        return {
            data: result.data.me.account as UserModel
        }
    });
}

export async function getUser(client: ApiClient, msisdn: string): Promise<any> {
    return queryUser(client, msisdn)
}

import {KTSVG} from '../../../../_metronic/helpers'
import {useEffect, FC} from 'react'

type Props = {
  content: string | undefined
  callback: any
}

export const HelpModal: FC<Props> = ({content, callback}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Помощь</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-tasks-modal-action='close'
                onClick={() => callback(false)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              {(content && <div dangerouslySetInnerHTML={{__html: content}}/>) || <span>Загрузка ...</span>}
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

import {FC} from 'react'

type Props = {
  value: string
}

const OrderCell: FC<Props> = ({value}) => {

    return (
        <div className='d-flex align-items-center'>
            <div className='d-flex flex-column'>
                {value}
            </div>
        </div>
    )
}

export {OrderCell}

import {Service, ServicesQueryResponse} from './_models'
import {ApiClient} from "../../../../components/ApiClient";
import {GET_DASHBOARD, GET_SERVICES} from "../../../../components/_queries";
import {ENABLE_SERVICE, DISABLE_SERVICE} from "../../../../components/_mutations";
import {generateCorrelationId} from '../../../../components/_helpers';

const getServices = async (client: ApiClient, msisdn: string): Promise<ServicesQueryResponse> => {
  let result = await client.query({query: GET_SERVICES, variables: {msisdn: msisdn}});
  return {
    data: result.data.me.account.billingNumber.services
  }
}
const getDashboard = async (client: ApiClient, msisdn: string): Promise<ServicesQueryResponse> => {
  let result = await client.query({query: GET_DASHBOARD, variables: {msisdn: msisdn}});
  return {
    data: result.data.me.account.billingNumber.services
  }
}

const enableService = async (client: ApiClient, service: Service, msisdn: String): Promise<object> => {
  let result = await client.mutate({
    mutation: ENABLE_SERVICE, variables: {
      availableServiceId: service.serviceId,
      targetMsisdn: msisdn,
      correlationId: generateCorrelationId(),
      actionId: generateCorrelationId()
    }
  });
  return {
    data: result.data.billingNumberServiceEnable
  }
}

const disableService = async (client: ApiClient, service: Service, msisdn: String): Promise<object> => {
  let result = await client.mutate({
    mutation: DISABLE_SERVICE, variables: {
      enabledServiceId: service.id,
      targetMsisdn: msisdn,
      correlationId: generateCorrelationId(),
      actionId: generateCorrelationId()
    }
  });
  return {
    data: result.data.billingNumberServiceDisable
  }
}

export {getServices, enableService, disableService, getDashboard}

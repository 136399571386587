import {Routes, Route, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import React from "react";
import {OrdersList} from "./orders-list/OrdersList";

const ordersBreadCrumbs: Array<PageLink> = [
    {
        title: 'Главная',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const OrdersPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/'
                    element={
                        <>
                            <PageTitle breadcrumbs={ordersBreadCrumbs}>Заявки</PageTitle>
                            <OrdersList />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default OrdersPage

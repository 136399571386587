export type Verification = {
    id: string
    isActive: boolean
    createdAt: string
    deadline: string
    timeout: string
  }

  export const initialVerificationValues = {
    id: '',
    isActive: true,
    createdAt: '',
    deadline: '',
    timeout: ''
  }